/**
 * This mixin provides screen property
 * It's not meant to be used directly.
 *
 * @mixin
 */
import { computed, ref, onMounted } from 'vue'
import { SCREEN, SCREEN_BREAKPOINT_MEDIUM, SCREEN_BREAKPOINT_LARGE } from 'awds'

export const useScreen = () => {
  const screenProp = {
    screen: {
      type: String,
      default: SCREEN.small.name,
      validator: (value: string) => [SCREEN.small.name, SCREEN.medium.name, SCREEN.large.name].includes(value)
    }
  }

  const screen = ref('small')

  const isScreenLarge = computed(() => screen.value === SCREEN.large.name)
  const isScreenMedium = computed(() => screen.value === SCREEN.medium.name)
  const isScreenSmall = computed(() => screen.value === SCREEN.small.name)

  const initListeners = () => {
    const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0
    screen.value = getScreen(windowWidth)
    window.addEventListener('resize', (event) => {
      const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0
      screen.value = getScreen(windowWidth)
    })
  }

  const getScreen = (width : number) => {
    switch (true) {
      case width >= SCREEN_BREAKPOINT_LARGE:
        return 'large'
      case width >= SCREEN_BREAKPOINT_MEDIUM:
        return 'medium'
      case width < SCREEN_BREAKPOINT_MEDIUM:
      default:
        return 'small'
    }
  }

  onMounted(() => {
    initListeners()
  })

  return {
    screen,
    screenProp,
    getScreen,
    initListeners,
    isScreenLarge,
    isScreenMedium,
    isScreenSmall
  }
}
