import { useRoutesUtils } from '../utils/useRoutesUtils'
import { useRoutes } from '../utils/useRoutes'
import type { AdyenRoute } from '../types'

export const useI18nPageInfo = () => {
  const { fetchRoutes, wrapLocale, isHome } = useRoutesUtils()
  const i18nParams = useState('i18n-params', () => {})
  const route = useRoute()

  const initSEO = async ({ sysId, targetSite = 'all', locale = 'en' }) => {
    const routes = await fetchRoutes(targetSite) as AdyenRoute[]
    const { getRouteParams } = useRoutes(routes, wrapLocale(locale))

    // TODO: If home, no generate
    const routeParams = getRouteParams(sysId, isHome(route.path))
    i18nParams.value = routeParams
    return routeParams
  }

  return {
    initSEO,
    i18nParams
  }
}
